<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        
      </v-col>

      <v-col class="mb-4">
        
        <h1 class="display-1 mb-3 font-weight-bold">
          <img src="../assets/wadmplogo.png" height="25rem" alt="WebAccess/DMP"> Status Page
        </h1>
      <br>
        <p class="subheading font-weight-regular">
          This is the Status page of WebAccess Device Management and Priovisoning Portal at 
          <a
            href="https://wadmp.com"
            target="_blank"
          >www.wadmp.com</a>.
          <br><br>
          We are continuously working on making our service better.
        </p>

      </v-col>

      
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Intro',

    data: () => ({
      //
    }),
  }
</script>
